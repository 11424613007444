<template>
  <div class="container layout-container">
    <div class="layout-bar">

    </div>
    <div class="layout-content" ref="container">
      <el-table :data="list" :height="height" v-loading="loading" border style="width: 100%">
        <el-table-column prop="id" label="id" width="80" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" width="140" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="avatar" label="头像" width="60" align="center">
          <template v-slot:default="scope">
            <img v-viewer class="headimg" :src="scope.row.avatar" v-if="scope.row.avatar" />
          </template>
        </el-table-column>
        <el-table-column prop="intro" label="简介" min-width="80" align="center"></el-table-column>
        <el-table-column prop="gender" label="性别" width="80" align="center"></el-table-column>
        <el-table-column prop="source_id" label="来源id" width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="source_from" label="来源" width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :column="scope.column" @filter="filter" />
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="layout-bbar">
      <page ref="page" @change="reload" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import page from '@/components/page/index'
import filterable from '@/components/table/header.filterable.vue'

export default {
  components: { page, filterable },

  data() {
    return {
      height: '200px',
      list: [],
      order: {
        orderBy: 'id',
        orderType: 'asc'
      },
      params: {
        name: ''
      },
      loading: false
    }
  },

  computed: {
    ...mapState({
      resized: state => state.runtime.resized,
    })
  },

  mounted() {
    this.resize()
    this.reload()
  },

  methods: {
    async reload() {
      try {
        this.loading = true
        const params = this.$refs.page.params({
          order_by: this.order.orderBy,
          order_type: this.order.orderType,
          ...this.params
        })
        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/resource/host/list',
          params: params
        })

        this.$refs.page.update(data)
        for (const item of data.data) {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated_at = dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')
          if (item.gender === 1) item.gender = '男'
          else if (item.gender === 2) item.gender = '女'
        }
        this.list = data.data
      } finally {
        this.loading = false
      }
    },

    resize() {
      this.height = this.$refs.container.clientHeight
    },

    onorder(order) {
      this.order = order
      this.reload()
    },

    filter({ id, value }) {
      this.params[id] = value
      this.reload()
    }
  }
}
</script>

<style>
.headimg{
  max-width:60px;
  max-height:60px;
}
</style>
